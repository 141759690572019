import * as THREE from 'three';
import turf from 'turf';
import { interpolate, combine, separate } from "flubber"
import voronoi from '@turf/voronoi';
import { intersect } from 'turf';

const isConvex = (arr = []) => {
    const { length } = arr;
    let pre = 0, curr = 0;
    for (let i = 0; i < length; ++i) {
       let dx1 = arr[(i + 1) % length][0] - arr[i][0];
       let dx2 = arr[(i + 2) % length][0] - arr[(i + 1) % length][0];
       let dy1 = arr[(i + 1) % length][1] - arr[i][1];
       let dy2 = arr[(i + 2) % length][1] - arr[(i + 1) % length][1];
       curr = dx1 * dy2 - dx2 * dy1;
       if (curr != 0) {
          if ((curr > 0 && pre < 0) || (curr < 0 && pre > 0))
             return false;
          else
             pre = curr;
       };
    };
    return true;
 };


function getInterpolator(map)
{
    if (map[0].type == 'm' && map[1].type == 'm')
        return {interpolator : (t) => [], type: 's'}
    else if(map[0].type == 'm')
    {
        let inter = combine(map[0].geo, map[1].geo, {string: false});
        return {interpolator: inter, type: 'm'};
    }
    else if (map[1].type == 'm')
    {
        let inter = separate(map[0].geo, map[1].geo, {string: false});
        return {interpolator: inter, type: 'm'};
    }
    else
    {
        let f, s;
        f = map[0].geo;

        s = map[1].geo;
        
        let inter = interpolate(f, s, {string: false});
        return {interpolator: inter, type: 's'};
    }
}

let slowf = (t) => {
    return (Math.sin(3.141592653589793 * t - 3.141592653589793/2) + 1)/2;
}

function toTurfPolygon(polygon)
{
    let tpO;
    if (polygon[0][0] != polygon.slice(-1)[0][0] || polygon[0][1] != polygon.slice(-1)[0][1])
        tpO = turf.polygon([[...polygon, polygon[0]]]);
    else
        tpO = turf.polygon([polygon]);

    return tpO;
}

function dist(a, b)
{
    return Math.sqrt(Math.pow(a[0] - b[0], 2.0) + Math.pow(a[1] - b[1], 2.0));
}

function nearestPoint(polygonO, polygonT)
{
    let tpO = toTurfPolygon(polygonO);
    var centroid = turf.centroid(tpO).geometry.coordinates;

    let min = 100000000;
    let nearest; 
    polygonT.forEach(point => {
        if (dist(point, centroid) < min)
        {
            nearest = point;
            min = dist(point, centroid);
        }
    })

    return nearest;
}

function farthestPoint(polygonO, polygonT)
{
    let tpO = toTurfPolygon(polygonO);
    var centroid = turf.centroid(tpO).geometry.coordinates;

    let max = 0;
    let farthest; 
    polygonT.forEach(point => {
        if (dist(point, centroid) > max)
        {
            farthest = point;
            max = dist(point, centroid);
        }
    })

    return farthest;
}


function Divide(polygonO, polygonsT)
{
    let voronoiPoints = [];
    let divisions = [];

    let tpO = toTurfPolygon(polygonO);
    let bbox = turf.bbox(tpO);
    polygonsT.forEach(polygonT => {
        if (!polygonT)
            return;
        let np = nearestPoint(polygonO, polygonT);
        //let np = farthestPoint(polygonO, polygonT);
        let tPoint = turf.point(np);

        voronoiPoints.push(tPoint);
    })

    let voronoiFeatures = turf.featureCollection(voronoiPoints);

    let voronoiPolygons = voronoi(voronoiFeatures, {bbox: bbox});

    let k = 0;
    polygonsT.forEach((polygonT, i) => {

        if (!polygonT)
        {
            divisions.push(null);
            return;
        }

        if (!voronoiPolygons.features[k])
        {
            divisions.push(null);
            k++;
            return;
        }

        let inter = turf.intersect(tpO, voronoiPolygons.features[k]);

        if (!inter)
        {
            divisions.push(null);
            k++;
            return;
        }

        if (inter.geometry.type == "Polygon")
            divisions.push({type: 'p', geo : inter.geometry.coordinates[0]});
        else if (inter.geometry.type == "MultiPolygon")
        {

            let res = {type: 'm', geo: []};
            inter.geometry.coordinates.forEach(c => res.geo.push(c[0]));
            divisions.push(res);
        }
        else
            divisions.push({type: 'p', geo: []});

        k++;
    })

    return divisions;
}


export default class InterpolatorStructure {
    constructor(renderer, camera, rsize, geometryData, t)
    {
        this.renderer = renderer;
        this.camera = camera;
        this.renderTarget = new THREE.WebGLRenderTarget( rsize, rsize, {
            wrapS: THREE.ClampToEdgeWrapping,
            wrapT: THREE.ClampToEdgeWrapping,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            stencilBuffer: false,
            depthBuffer: true
        });

        this.scene = new THREE.Scene();

        const material0 = new THREE.MeshBasicMaterial( { color: 0x1A9850, side: THREE.DoubleSide, transparent: true, opacity: 0.0, blending : THREE.NoBlending } );
        const material20 = new THREE.MeshBasicMaterial( { color: 0x1A9850, side: THREE.DoubleSide, transparent: true, opacity: 0.2, blending : THREE.NoBlending } );
        const material40 = new THREE.MeshBasicMaterial( { color: 0x91CF60, side: THREE.DoubleSide, transparent: true, opacity: 0.4, blending : THREE.NoBlending } );
        const material60 = new THREE.MeshBasicMaterial( { color: 0xD4E70D, side: THREE.DoubleSide, transparent: true, opacity: 0.6, blending : THREE.NoBlending } );
        const material80 = new THREE.MeshBasicMaterial( { color: 0xC52E35, side: THREE.DoubleSide, transparent: true, opacity: 0.8, blending : THREE.NoBlending } );
        const material100 = new THREE.MeshBasicMaterial( { color: 0xEF180C, side: THREE.DoubleSide, transparent: true, opacity: 1.0, blending : THREE.NoBlending } );


        let geo = new THREE.BufferGeometry();

        this.meshMap = {
                '0': new THREE.Mesh(geo, material0),
                '20': new THREE.Mesh(geo, material20),
                '40': new THREE.Mesh(geo, material40),
                '60': new THREE.Mesh(geo, material60),
                '80': new THREE.Mesh(geo, material80),
                '100': new THREE.Mesh(geo, material100)
            }

        Object.values(this.meshMap).forEach(mesh => this.scene.add(mesh))

        this.buildInterpolator(geometryData);

        this.t = t;

    }

    buildInterpolator(geometryData) {

        let divisionStructure = {
            '0': {},
            '20': {},
            '40': {},
            '60': {},
            '80': {},
            '100': {}
        }


        Object.keys(divisionStructure).forEach(value => {
            divisionStructure[value].origin = geometryData[0][value];
            divisionStructure[value].target = geometryData[1][value];

            if (!geometryData[0][value] || !geometryData[1][value])
            {
                divisionStructure[value].corrOrigin = null;
                divisionStructure[value].corrTarget = null;

                return;
            }

            divisionStructure[value].corrOrigin = divisionStructure[value].origin.map(x => []);
            divisionStructure[value].corrTarget = divisionStructure[value].target.map(x => []);

            let intersections = [];
            divisionStructure[value].origin.forEach((polygonO, i) => {
                divisionStructure[value].target.forEach((polygonT, j) => {

                    let tpO, tpT;
                    tpO = toTurfPolygon(polygonO);
                    tpT = toTurfPolygon(polygonT);


                    let intersection = intersect(tpO, tpT);

                    if (intersection)
                    {
                        let area = turf.area(intersection);

                        let areaO = turf.area(tpO);
                        let areaT = turf.area(tpT);

                        let index = 0;
                        for (let m = 0; m < intersections.length; m++)
                        {
                            if (area < intersections[m].area)
                                index = m + 1;
                        }

                        //console.log(areaO/areaT, areaT/areaO)
                        if (areaO/areaT < 4.0 && areaT/areaO < 4.0)
                            intersections = [...intersections.slice(0, index), { area : area, oi: i, ti: j, o: polygonO, t: polygonT }, ...intersections.slice(index)];
                    }

                    divisionStructure[value].corrOrigin[i].push(null);
                    divisionStructure[value].corrTarget[j].push(null);
                    
                    // if (intersection)
                    // {
                    //     divisionStructure[value].corrOrigin[i].push(polygonT);
                    //     divisionStructure[value].corrTarget[j].push(polygonO);

                    // }
                    // else
                    // {
                    //     divisionStructure[value].corrOrigin[i].push(null);
                    //     divisionStructure[value].corrTarget[j].push(null);
                    // }

                })
            })

            let intersectedOs = [];
            let intersectedTs = [];

            //console.log(intersections)

            intersections.forEach(intersection => {

                if (!intersectedOs.includes(intersection.oi) && !intersectedTs.includes(intersection.ti))
                {
                    divisionStructure[value].corrOrigin[intersection.oi][intersection.ti] = intersection.t;
                    divisionStructure[value].corrTarget[intersection.ti][intersection.oi] = intersection.o;

                    intersectedOs.push(intersection.oi);
                    intersectedTs.push(intersection.ti);
                }

            })

        })


        Object.keys(divisionStructure).forEach(value => {

            divisionStructure[value].polygonMaps = [];
            divisionStructure[value].RO = divisionStructure[value].origin? divisionStructure[value].origin.map(x => []) : null;
            divisionStructure[value].RT = divisionStructure[value].target? divisionStructure[value].target.map(x => []) : null;

            if (divisionStructure[value].origin)
                divisionStructure[value].origin.forEach((polygonO, i) => {
                    let lO = divisionStructure[value].corrOrigin? divisionStructure[value].corrOrigin[i].reduce((p,c) => {return c? p + 1: p}, 0) : 0.0;
                    if (lO == 0)
                    {
                        let tpO = toTurfPolygon(polygonO);
                        //var centroid = turf.centroid(tpO).geometry.coordinates;
                        var centroid = polygonO[0];
                        //if ( turf.area(tpO)/ Math.pow(2, 18) < 101817600.77890106323)
                        divisionStructure[value].polygonMaps.push([{type: 'p', geo: polygonO}, {type :'p', geo: [centroid]}]);
                    }
                    else
                        divisionStructure[value].RO[i] = Divide(polygonO, divisionStructure[value].corrOrigin[i])

                })

            if (divisionStructure[value].target)
                divisionStructure[value].target.forEach((polygonT, i) => {
                    let lT = divisionStructure[value].corrTarget? divisionStructure[value].corrTarget[i].reduce((p,c) => {return c? p + 1: p}, 0) : 0.0;
                    if (lT == 0)
                    {
                        let tpT = toTurfPolygon(polygonT);
                        //var centroid = turf.centroid(tpT).geometry.coordinates;
                        var centroid = polygonT[0];
                        //if ( turf.area(tpT)/ Math.pow(2, 18) < 101817600.77890106323)
                        divisionStructure[value].polygonMaps.push([{type: 'p', geo: [centroid]}, {type: 'p', geo: polygonT}]);
                    }
                    else
                        divisionStructure[value].RT[i] = Divide(polygonT, divisionStructure[value].corrTarget[i])

                })

            if (divisionStructure[value].target)
                divisionStructure[value].target.forEach((polygonT, j) => {

                    if (divisionStructure[value].corrTarget)
                        divisionStructure[value].corrTarget[j].forEach((polygonO, i) => {

                            if (divisionStructure[value].RO[i][j] && divisionStructure[value].RT[j][i])
                                divisionStructure[value].polygonMaps.push([divisionStructure[value].RO[i][j], divisionStructure[value].RT[j][i]]);

                        })

                })

        })


        let interpolators = {
            '0': [],
            '20': [],
            '40': [],
            '60': [],
            '80': [],
            '100': []
        };

        Object.keys(interpolators).forEach(value => {

            divisionStructure[value].polygonMaps.forEach(map => {

                let interpolator = getInterpolator(map);
                interpolators[value].push(interpolator);

            })
        })

        this.interpolators = interpolators;
    }

    render(first, second, gObj)
    {
        Object.keys(this.interpolators).forEach(value => {
        
            let polys = [];
    
            this.interpolators[value].forEach(interpolator => {
    
                if (interpolator.type == 's')
                {
                    let polygon = interpolator.interpolator(slowf(gObj.t - first));
                    if (polygon.length > 0)
                    {
                        //polygon = hull(polygon, 40);
                        polys.push(polygon.map(c => new THREE.Vector2(c[0], c[1])));
                    }
                }
                else if (interpolator.type == 'm')
                {
                    interpolator.interpolator.forEach(i => {
                        let polygon = i(slowf(gObj.t - first));
                        polys.push(polygon.map(c => new THREE.Vector2(c[0], c[1])));
                    })
                }
            })
    
    
            if (polys.length != 0)
            {
                let shapes = polys.map(x => new THREE.Shape(x));
                let geo = new THREE.ShapeGeometry(shapes);
                this.meshMap[value].visible = true;
                this.meshMap[value].geometry.dispose();
                this.meshMap[value].geometry = geo;
            }
            else
                this.meshMap[value].visible = false;
            
            
        })
    
        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.scene, this.camera);
        this.renderer.setRenderTarget(null);
    }
}
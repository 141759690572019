// import L from 'leaflet'
import * as THREE from "three";

import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
import { WindLayer } from "./leafletLayer";

let container, renderer, camera, fj, map, featuresTimed;
let interpolatorsT, windTexture, frameStructures, pgRenderer;
let gui;
let windSim;
let shaders;
let pause = false;
let startScale = 4;
let mapLayer, windLayer, tempLayer, pressureLayer, precipitationLayer;
let gObj = {
  t: 10,
  factor: 0.8, //1.0,
  fade: 0.05, //0.05,
  blur1: 3, //3
  blur2: 9, //9
  layer: "temperature",
  pointSize: 1.0, // 1.0
  scale: 1.0,
  particleWidth: 256,
  particleHeight: 256,
  windColor: true,
  notRenderPoints: false,
  zoomWind: false,
  lowVelocityColor: "#F637EC", //[246.0 , 55.0 , 236.0 ],
  highVelocityColor: "#72FFFF", //[114.0, 255.0, 255.0],
  gradientFactor: 2.0,
  backgroundColor: "#000000",
  backgroundOpacity: 0.0,

  pause: () => {
    pause = !pause;
  },

  filterOn: () => {
    filterOn = !filterOn;
  },

  // clear: () => {
  //   renderer.setRenderTarget(pointsRT);
  //   renderer.clear();
  //   renderer.setRenderTarget(prevPointsRT);
  //   renderer.clear();
  //   renderer.setRenderTarget(null);
  // },
};

async function loadJSON() {
  return fetch("conc.geojson")
    .then((response) => response.json())
    .then((json) => json);
}

function readFile(features) {
  let fs = features.features;

  let featuresT = [];

  for (let j = 0; j < 37; j++) featuresT.push([]); // 0, 20, 40, 60, 80, 100

  for (let j = 0; j < 37; j++) {
    fs.forEach((f) => {
      if (f.properties.t == j && f.properties.impurity == "NO")
        featuresT[j].push(f);
    });
  }

  return featuresT;
}

function buildGUI(gObj, map) {
  gui = new GUI();
  gui.useLocalStorage = true;
  var f1 = gui.addFolder("Interpolate/Gas");
  f1.add(gObj, "t", 4, 34.99).listen(); //34.99
  f1.add(gObj, "blur1", 1.0, 15.0).listen();
  f1.add(gObj, "blur2", 1.0, 15.0).listen();
  f1.add(gObj, "pause").listen();
  f1.add(gObj, "filterOn").listen();

  var f2 = gui.addFolder("Particles/Wind");
  f2.add(gObj, "factor", 0.1, 5.0).listen();
  f2.add(gObj, "fade", 0.001, 0.2).listen();
  f2.add(gObj, "pointSize", 0.5, 4.0).listen();
  f2.add(gObj, "particleWidth", 10, 256)
    .listen()
    .onChange((v) => {
      if (gObj.particleUpdate) gObj.particleUpdate(v, gObj.particleHeight);
    });
  f2.add(gObj, "particleHeight", 10, 256)
    .listen()
    .onChange((v) => {
      if (gObj.particleUpdate) gObj.particleUpdate(gObj.particleWidth, v);
    });

  f2.add(gObj, "gradientFactor", 0.5, 4).listen();
  f2.add(gObj, "backgroundOpacity", 0.0, 1).listen();

  f2.addColor(gObj, "lowVelocityColor").listen();
  f2.addColor(gObj, "highVelocityColor").listen();
  f2.addColor(gObj, "backgroundColor").listen();

  f2.add(gObj, "windColor", false, true)
    .listen()
    .onChange((v) => {
      map.removeLayer(windLayer);
      if (v) map.addLayer(windLayer);
    });

  f2.add(gObj, "layer", ["none", "temperature", "pressure", "precipitation"])
    .listen()
    .onChange((v) => {
      if (v == "none") {
        map.removeLayer(tempLayer);
        map.removeLayer(pressureLayer);
        map.removeLayer(precipitationLayer);
      }
      if (v == "temperature") {
        map.removeLayer(tempLayer);
        map.removeLayer(pressureLayer);
        map.removeLayer(precipitationLayer);

        map.addLayer(tempLayer);
      }

      if (v == "pressure") {
        map.removeLayer(tempLayer);
        map.removeLayer(pressureLayer);
        map.removeLayer(precipitationLayer);

        map.addLayer(pressureLayer);
      }

      if (v == "precipitation") {
        map.removeLayer(tempLayer);
        map.removeLayer(pressureLayer);
        map.removeLayer(precipitationLayer);

        map.addLayer(precipitationLayer);
      }
    });
  // f2.add(gObj, "clear").listen();

  return gui;
}

let init = async () => {
  windTexture = new THREE.TextureLoader().load("2016112000.png");
  fj = await loadJSON();
  featuresTimed = readFile(fj);

  map = L.map("map", {
    center: [
      (56.806893398067466 + 54.754746221869) / 2,
      (46.834716796875 + 41.44317626953126) / 2,
    ],
  }).setView(
    [
      (56.806893398067466 + 54.754746221869) / 2,
      (46.834716796875 + 41.44317626953126) / 2,
    ],
    startScale,
  ); //55,782177808378733,  44,13894653320313

  let tileSize = 256;

  mapLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    tileSize: tileSize,
  });

  tempLayer = L.tileLayer(
    "https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=7adac4c05301d7c2f59d24b6915f5b2c",
    {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      tileSize: tileSize,
    },
  );
  //https://tile.openweathermap.org/map/pressure_new/9/0/2.png?appid=7adac4c05301d7c2f59d24b6915f5b2c

  windLayer = L.tileLayer(
    "https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=7adac4c05301d7c2f59d24b6915f5b2c",
    {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      tileSize: tileSize,
    },
  );

  pressureLayer = L.tileLayer(
    "https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=7adac4c05301d7c2f59d24b6915f5b2c",
    {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      tileSize: tileSize,
    },
  );

  precipitationLayer = L.tileLayer(
    "https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=7adac4c05301d7c2f59d24b6915f5b2c",
    {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      tileSize: tileSize,
    },
  );

  let wL = new WindLayer({
    featuresTimed: featuresTimed,
    windTexture: windTexture,
    gObj: gObj,
  });
  renderer = wL.renderer;

  buildGUI(gObj, map);

  map.addLayer(mapLayer);
  map.addLayer(tempLayer);
  map.addLayer(windLayer);
  map.addLayer(wL);

  container = document.getElementById("container");
  window.addEventListener("resize", () => {
    wL.onResize();
    // renderer.setSize(window.innerWidth, window.innerHeight);
  });
};

window.addEventListener("load", init);

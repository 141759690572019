import * as THREE from 'three';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { ShaderMaterial } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

let interp = {
    uniforms: {

        tDiffuse: { type: 't', value: null },
        wind: { type: 't', value: null },
        points: { type: 't', value: null },
        prevPoints: { type: 't', value: null },
        t : { type: 'f', value: 0},
        fade: {type: 'f', value : 0.05}
    },
    vertexShader: `
        varying vec2 vUv;

        void main() {

            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

        }
    `,
    fragmentShader: `

        uniform float fade;
        uniform sampler2D tDiffuse;
        uniform sampler2D wind;

        uniform sampler2D points;
        uniform sampler2D prevPoints;

        vec4 lookup(const vec2 uv) {
            // return texture2D(tDiffuse, uv).rg; // lower-res hardware filtering
            vec2 res = vec2(512.0, 512.0);
            vec2 px = 1.0 / res;
            vec2 vc = (floor(uv * res)) * px;
            vec2 f = fract(uv * res);
            vec4 tl = texture2D(tDiffuse, vc).rgba;
            vec4 tr = texture2D(tDiffuse, vc + vec2(px.x, 0)).rgba;
            vec4 bl = texture2D(tDiffuse, vc + vec2(0, px.y)).rgba;
            vec4 br = texture2D(tDiffuse, vc + px).rgba;
            return mix(mix(tl, tr, f.x), mix(bl, br, f.x), f.y);
        }

        varying vec2 vUv;


        void main()
        {

            //gl_FragColor = vec4(lookup(vUv));
            gl_FragColor = (1.0 - fade) * texture2D(points, vUv) + texture2D(tDiffuse, vUv) ;
            //if (gl_FragColor.w > 0.1)
            //gl_FragColor.w = 0.6;
        } 

    `
}


let shader = {
    uniforms: {

        t1: { type: 't', value: null },
		t2: { type: 't', value: null },
        r: { type: 't', value: null },
        t : { type: 'f', value: 0},
        blur1: { type: 'f', value: 3}
    },
    vertexShader: `
        varying vec2 vUv;

        void main() {

            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

        }
    `,
    fragmentShader: `
    #define NUM_SAMPLES 17
    #define NUM_RINGS 11
    #define PI2 6.283185307179586
    #define PI 3.14

    uniform float t;
    uniform float blur1;

    uniform sampler2D t1;
    uniform sampler2D t2;

    uniform sampler2D r;

    varying vec2 vUv;

    highp float rand( const in vec2 uv ) {
        const highp float a = 12.9898, b = 78.233, c = 43758.5453;
        highp float dt = dot( uv.xy, vec2( a, b ) ), sn = mod( dt, PI );
        return fract( sin( sn ) * c );
    }


    vec4 sampleT(vec2 vUv)
    {
        vec4 before = texture2D(t1, vUv);
        vec4 after = texture2D(t2, vUv);

        vec4 rendered = texture2D(r, vUv);

    
        if (before.w < 0.05)
        {
            return t * after;
        }
        else if (after.w < 0.05)
        {
            return (1.0 - t) * before;
        }
        else if (before == after)
            return before;
        else
        {
            // if (rendered.w > 0.05)
            //     return rendered; // + (1.0 - t) * before + t * after) / 2.0;
            // else
            return (1.0 - t) * before + t * after;
        }
    }


    vec3 rgb2hsv(vec3 c)
    {
        vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
        vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
        vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

        float d = q.x - min(q.w, q.y);
        float e = 1.0e-10;
        return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
    }

    vec3 hsv2rgb(vec3 c)
    {
        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
    }


    float normpdf(in float x, in float sigma)
    {
        return 0.39894*exp(-0.5*x*x/(sigma*sigma))/sigma;
    }

    float kernel[16];
    

    void main() {


        float texel = 7.0/512.0;

        int mSize = int(blur1);
        int kSize = (mSize-1)/2;
        vec4 fragColor;
        vec2 fragCoord = gl_FragCoord.xy;
        vec4 final_colour = vec4(0.0);
        
        //create the 1-D kernel
        float sigma = 7.0;
        float Z = 0.0;
        for (int j = 0; j <= kSize; ++j)
        {
            kernel[kSize+j] = kernel[kSize-j] = normpdf(float(j), sigma);
        }
        
        //get the normalization factor (as the gaussian has been clamped)
        for (int j = 0; j < mSize; ++j)
        {
            Z += kernel[j];
        }
        
        //read out the texels
        vec2 res = vec2(512.0, 512.0);
        for (int i=-kSize; i <= kSize; ++i)
        {
            for (int j=-kSize; j <= kSize; ++j)
            {
                final_colour += kernel[kSize+j]*kernel[kSize+i]*sampleT(vUv+ 1.0 * vec2(float(i),float(j)) / res.xy).rgba;
    
            }
        }
    
    
        fragColor = final_colour/(Z*Z);


        gl_FragColor = fragColor;

        //gl_FragColor = sampleT(vUv);

        

    }
    `
}

let deblur = {
    uniforms: {

        tDiffuse: { type: 't', value: null },
        t : { type: 'f', value: 0},
        blur2: { type: 'f', value: 11}
    },
    vertexShader: `
        varying vec2 vUv;

        void main() {

            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

        }
    `,
    fragmentShader: `

        #define OFFSET  1.5
        #define DEBLUR  4.0
        #define SMART   0.5

        #define NUM_SAMPLES 17
        #define NUM_RINGS 11
        #define PI2 6.283185307179586
        #define PI 3.14

        uniform sampler2D tDiffuse;
        uniform float blur2;
        varying vec2 vUv;

        vec3  dt = vec3(1.,1.,1.);
        vec3  dtt = vec3(0.0001, 0.0001, 0.0001); 

        float wt(vec3 A, vec3 B)
        {	
            return 4.0*length(A-B)/(dot(A+B,dt)+0.33);
        } 

        highp float rand( const in vec2 uv ) {
            const highp float a = 12.9898, b = 78.233, c = 43758.5453;
            highp float dt = dot( uv.xy, vec2( a, b ) ), sn = mod( dt, PI );
            return fract( sin( sn ) * c );
        }
        vec3 rgb2hsv(vec3 c)
        {
            vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
            vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
            vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

            float d = q.x - min(q.w, q.y);
            float e = 1.0e-10;
            return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
        }

        vec3 hsv2rgb(vec3 c)
        {
            vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
            vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
            return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
        }

        vec3 getColor(float y)
        {

            vec3 color1 = vec3(153.0, 0.0, 0.0) / 255.0;
            vec3 color2 = vec3(234.0, 229.0, 9.0) / 255.0;
            vec3 color3 = vec3(43.0, 122.0, 11.0) / 255.0;


            float step1 = 1.0;
            float step2 = 0.5;
            float step3 = 0.0;

        

            vec3 color = (y >= step2 && y <= step1 )? mix(color1, color2, smoothstep(step1, step2, y)) : vec3(0.0, 0.0, 0.0);
            color += (y < step2 && y >= step3 )? mix(color2, color3, smoothstep(step2, step3, y)) : vec3(0.0, 0.0, 0.0);



            return color;


        }

        float normpdf(in float x, in float sigma)
        {
            return 0.39894*exp(-0.5*x*x/(sigma*sigma))/sigma;
        }

        float kernel[16];

        void main()
        {
            int mSize = int(blur2);
            int kSize = (mSize-1)/2;

            vec4 fragColor;
            vec2 fragCoord = gl_FragCoord.xy;
            vec4 final_colour = vec4(0.0);
            
            //create the 1-D kernel
            float sigma = 7.0;
            float Z = 0.0;
            for (int j = 0; j <= kSize; ++j)
            {
                kernel[kSize+j] = kernel[kSize-j] = normpdf(float(j), sigma);
            }
            
            //get the normalization factor (as the gaussian has been clamped)
            for (int j = 0; j < mSize; ++j)
            {
                Z += kernel[j];
            }
            
            //read out the texels
            vec2 res = vec2(512.0, 512.0);
            for (int i=-kSize; i <= kSize; ++i)
            {
                for (int j=-kSize; j <= kSize; ++j)
                {
                    final_colour += kernel[kSize+j]*kernel[kSize+i]*texture2D(tDiffuse, vUv+ 1.0 * vec2(float(i),float(j)) / res.xy).rgba;
        
                }
            }
		
		
		    fragColor = final_colour/(Z*Z);


            gl_FragColor = fragColor;

            gl_FragColor.xyz = getColor(gl_FragColor.w);


            if (gl_FragColor.w > 0.1)
                gl_FragColor.w = (gl_FragColor.w / 2.0) + 0.6;

        } 

    `
}

let slowf = (t) => {
    return (Math.sin(3.141592653589793 * t - 3.141592653589793/2) + 1)/2;
}


export default class polygonRenderer {
    constructor(renderer, frameStructures, interpolatorsT, shaders)
    {
        this.renderer = renderer;
        this.frameStructures = frameStructures;
        this.interpolatorsT = interpolatorsT;

        this.composer = new EffectComposer( renderer ); 
        this.sP = new ShaderPass( shader );
        this.dP = new ShaderPass( deblur );
        this.tP = new ShaderPass( interp );

        this.sP.needsSwap = true;
        this.dP.needsSwap = true;

        this.composer.addPass(this.sP);
        this.composer.addPass(this.dP);
        this.composer.addPass(this.tP);

        this.sP.fsQuad.material.transparent = true;
        this.sP.fsQuad.material.blending = THREE.AdditiveBlending;

        this.dP.fsQuad.material.transparent = true;
        this.dP.fsQuad.material.blending = THREE.AdditiveBlending;

        this.tP.fsQuad.material.transparent = true;
        this.tP.fsQuad.material.blending = THREE.AdditiveBlending;


    }

    render(first, second, gObj, windSimTexture) {

        this.frameStructures[first].render();
        this.frameStructures[second].render();

        this.interpolatorsT[first].render(first, second, gObj);

        if (gObj.filterOn)
        {       
            this.renderer.setRenderTarget(null);

            this.sP.uniforms.t1.value = this.frameStructures[first].renderTarget.texture;
            this.sP.uniforms.t2.value = this.frameStructures[second].renderTarget.texture;
            this.sP.uniforms.r.value = this.interpolatorsT[first].renderTarget.texture;
            this.sP.uniforms.blur1.value = gObj.blur1;
            this.sP.uniforms.t.value = slowf(gObj.t - first);//Math.sqrt(gObj.t - first);

            this.dP.uniforms.blur2.value = gObj.blur2;

            this.tP.uniforms.points.value = windSimTexture;
            this.tP.uniforms.fade.value = gObj.fade;
            this.composer.render();
        }
    }
}
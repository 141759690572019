import * as THREE from 'three';

export default class FixedFrameStructure {
    constructor(renderer, camera, geoMap, rsize)
    {
        this.renderer = renderer;
        this.camera = camera;
        this.renderTarget = new THREE.WebGLRenderTarget( rsize, rsize, {
            wrapS: THREE.ClampToEdgeWrapping,
            wrapT: THREE.ClampToEdgeWrapping,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            stencilBuffer: false,
            depthBuffer: true
        });

        this.scene = new THREE.Scene();

        const material0 = new THREE.MeshBasicMaterial( { color: 0x1A9850, side: THREE.DoubleSide, transparent: true, opacity: 0.0, blending : THREE.NoBlending } );
        const material20 = new THREE.MeshBasicMaterial( { color: 0x1A9850, side: THREE.DoubleSide, transparent: true, opacity: 0.2, blending : THREE.NoBlending } );
        const material40 = new THREE.MeshBasicMaterial( { color: 0x91CF60, side: THREE.DoubleSide, transparent: true, opacity: 0.4, blending : THREE.NoBlending } );
        const material60 = new THREE.MeshBasicMaterial( { color: 0xD4E70D, side: THREE.DoubleSide, transparent: true, opacity: 0.6, blending : THREE.NoBlending } );
        const material80 = new THREE.MeshBasicMaterial( { color: 0xC52E35, side: THREE.DoubleSide, transparent: true, opacity: 0.8, blending : THREE.NoBlending } );
        const material100 = new THREE.MeshBasicMaterial( { color: 0xEF180C, side: THREE.DoubleSide, transparent: true, opacity: 1.0, blending : THREE.NoBlending } );


        // var r = 100;

        let buildGeo = (geometry) => {
            let r = geometry? new THREE.ShapeGeometry( geometry.map(arr => new THREE.Shape(arr.map(c => new THREE.Vector2(c[0], c[1])))) ) : new THREE.BufferGeometry();

            return r;
        };

        let mesh0 = new THREE.Mesh(buildGeo(geoMap['0']), material0);
        mesh0.position.z = 0.3;
        let mesh20 = new THREE.Mesh(buildGeo(geoMap['20']), material20);
        mesh20.position.z = 0.4;
        let mesh40 = new THREE.Mesh(buildGeo(geoMap['40']), material40);
        mesh40.position.z = 0.5;
        let mesh60 = new THREE.Mesh(buildGeo(geoMap['60']), material60);
        mesh60.position.z = 0.6;
        let mesh80 = new THREE.Mesh(buildGeo(geoMap['80']), material80);
        mesh80.position.z = 0.7;
        let mesh100 = new THREE.Mesh(buildGeo(geoMap['100']), material100);
        mesh100.position.z = 0.8;

        this.scene.add(mesh0);
        this.scene.add(mesh20);
        this.scene.add(mesh40);
        this.scene.add(mesh60);
        this.scene.add(mesh80);
        this.scene.add(mesh100);

        // meshMap = {
        //     '0': mesh0,
        //     '20': mesh20,
        //     '40': mesh40,
        //     '60': mesh60,
        //     '80': mesh80,
        //     '100': mesh100,
        // }


        // let matMap = {
        //     '0': material0,
        //     '20': material20,
        //     '40': material40,
        //     '60': material60,
        //     '80': material80,
        //     '100': material100,
        // }
    }

    render(){

        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.scene, this.camera);
        this.renderer.setRenderTarget(null);
    }
}
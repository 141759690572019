import * as THREE from "three";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";

import shadersLoad from "./shaders";
import InterpolationStructure from "./interpolator";
import FixedFrameStructure from "./fixedFrame";
import WindSimStructure from "./windSim";
import PolygonRenderer from "./polygonRenderer";

let startScale = 4;
let pause = false;

function featuresToGeometry(fc, map) {
  let valueMap = {
    0: null,
    20: null,
    40: null,
    60: null,
    80: null,
    100: null,
  };

  fc.forEach((f) => {
    let polys = f.geometry.coordinates.map((x) => {
      let r = x[0];
      return r.map((y) => [
        map.project(L.latLng(y[1], y[0])).x,
        map.project(L.latLng(y[1], y[0])).y,
      ]);
    });

    let ret = polys;
    valueMap[f.properties.value] = ret;
  });

  return valueMap;
}

export class WindLayer extends L.Layer {
  constructor(options) {
    super(options);

    this.shaders = shadersLoad();
    this.featuresTimed = options.featuresTimed;
    this.windTexture = options.windTexture;

    if (options.gui) this.gui = options.gui;

    this.gObj = options.gObj;
  }

  onResize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  onAdd(map) {
    let featuresTimed = this.featuresTimed;

    let renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer = renderer;
    renderer.setClearColor(0x000000, 0);
    renderer.setSize(window.innerWidth, window.innerHeight);

    let container = document.getElementById("container");
    container.appendChild(renderer.domElement);

    this._container = container;
    this._container.style.position = "absolute";

    this.getPane().appendChild(this._container);

    let { max, min } = map.getPixelBounds();
    this.camera = new THREE.OrthographicCamera(
      min.x,
      max.x,
      min.y,
      max.y,
      0.1,
      3,
    );
    this.camera.position.set(0, 0, 1);

    let interpolatorsT = [];
    let frameStructures = [];
    let rsize = 128;
    for (let t = 0; t < featuresTimed.length - 1; t++) {
      let interpolationData = [featuresTimed[t], featuresTimed[t + 1]].map(
        (fc) => featuresToGeometry(fc, map),
      );
      frameStructures.push(
        new FixedFrameStructure(
          renderer,
          this.camera,
          featuresToGeometry(featuresTimed[t], map),
          rsize,
        ),
      );
      interpolatorsT.push(
        new InterpolationStructure(
          renderer,
          this.camera,
          rsize,
          interpolationData,
          t,
        ),
      );
    }
    frameStructures.push(
      new FixedFrameStructure(
        renderer,
        this.camera,
        featuresToGeometry(featuresTimed[featuresTimed.length - 1], map),
        rsize,
      ),
    );

    this.windSim = new WindSimStructure(
      renderer,
      map,
      this.camera,
      this.shaders,
      this.windTexture,
      map.getBounds(),
      1,
    );
    this.pgRenderer = new PolygonRenderer(
      renderer,
      frameStructures,
      interpolatorsT,
      this.shaders,
    );

    this.gObj.particleUpdate = (width, height) => {
      this.windSim.updateNumberParticles(width, height);
    };

    map.on("zoomstart", (e) => {
      let s = map.getZoomScale(map.getZoom(), startScale);

      this.camera.left = min.x / s;
      this.camera.right = max.x / s;
      this.camera.top = min.y / s;
      this.camera.bottom = max.y / s;

      this.camera.updateProjectionMatrix();

      this.gObj.notRenderPoints = true;
    });

    map.on("movestart", () => {
      let s = map.getZoomScale(map.getZoom(), startScale);

      this.camera.left = min.x / s;
      this.camera.right = max.x / s;
      this.camera.top = min.y / s;
      this.camera.bottom = max.y / s;

      this.camera.updateProjectionMatrix();

      this.gObj.notRenderPoints = true;
    });

    map.on("moveend", () => {
      this.gObj.zoomWind = true;
      this.gObj.notRenderPoints = false;
    });

    map.on("zoomend", () => {
      this.gObj.zoomWind = true;
      this.gObj.notRenderPoints = false;
    });

    this.animate(map);
  }

  onRemove(map) {
    this._container.remove();
  }

  animate(map) {
    let gObj = this.gObj;

    requestAnimationFrame(() => {
      this.animate(map);
    });

    let point = map._getMapPanePos();

    L.DomUtil.setPosition(this._container, L.point(0, 0).subtract(point));

    let { max, min } = map.getPixelBounds();
    let s = map.getZoomScale(map.getZoom(), startScale);

    this.renderer.setClearColor(gObj.backgroundColor, gObj.backgroundOpacity);

    //console.log(map.getBounds())

    gObj.scale = s;

    if (!pause) gObj.t += 1.0 / 180;

    if (gObj.t > 34.99) gObj.t = gObj.t - 34.99;

    let first = Math.floor(gObj.t);
    let second = first + 1;

    this.camera.left = min.x / s;
    this.camera.right = max.x / s;
    this.camera.top = min.y / s;
    this.camera.bottom = max.y / s;

    this.camera.updateProjectionMatrix();

    this.windSim.update(gObj, map.getBounds(), s);
    this.pgRenderer.render(first, second, gObj, this.windSim.getWindPos());
  }
}

import * as THREE from 'three';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

//integer valued bounds, latlng, right format
function getBounds(bounds)
{
    return {
        min: {
            x: Math.max(Math.floor(bounds._southWest.lng), -180),
            y: Math.max(Math.floor(bounds._southWest.lat), -90 )
        },
        max: {
            x: Math.min(Math.floor(bounds._northEast.lng) + 1, 180 ),
            y: Math.min(Math.floor(bounds._northEast.lat) + 1, 90)
        }
    }
}

export default class PositionVelocityStructure {
    constructor(originalVelocityTexture, oWidth, oHeight, tWidth, tHeight, map, bounds, renderer, shaders, scale)
    {
        this.originalVelocityTexture = originalVelocityTexture;
        this.velocityTexture = originalVelocityTexture;
        this.originalBounds = {
            min: {
                x: -180,
                y: -90
            },
            max: {
                x: 180,
                y: 90
            }
        };
        this.bounds = getBounds(bounds);
        this.oWidth = oWidth;
        this.oHeight = oHeight;
        this.tWidth = tWidth;
        this.tHeight = tHeight;
        this.map = map;
        this.renderer = renderer;
        this.scale = scale;

        this.zoomRT = new THREE.WebGLRenderTarget( tWidth, tHeight, {
            wrapS: THREE.ClampToEdgeWrapping,
            wrapT: THREE.ClampToEdgeWrapping,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            stencilBuffer: false,
            depthBuffer: true
        });

        this.pass = new ShaderPass({
            uniforms: {
                tex: {type: 't', value : originalVelocityTexture},
                startPos: {type: 'v2', value: new THREE.Vector2(0, 0)},
                size: {type: 'v2', value: new THREE.Vector2(tWidth, tHeight)},
                fade: {type: 'f', value : 0.05}
            },
            vertexShader: shaders.quad_v,
            fragmentShader: shaders.zoom_f
        })

        this.pass.fsQuad.material.transparent = true;
        this.pass.fsQuad.material.blending = THREE.NoBlending;
        this.pass.fsQuad.material.depthTest = false;

        this.zoom(bounds);
    }

    generatePositions()
    {
        let bounds = this.bounds;
        let map = this.map;
        let tWidth = this.tWidth;
        let tHeight = this.tHeight;

        let positions = new Float32Array(tWidth*tHeight*4);



        let pixelHeight = (bounds.max.y - bounds.min.y) / tHeight;
        let pixelWidth = (bounds.max.x - bounds.min.x) / tWidth;

        //let min = map.project(L.latLng(bounds.min.y, bounds.min.x));
        //let max = map.project(L.latLng(bounds.max.y, bounds.max.x));

        //let pixelHeight = (max.y - min.y) / tHeight;
        //let pixelWidth = (max.x - min.x) / tWidth;

        for( let i = 0; i < tWidth; i++)
        {
            for(let j = 0; j < tHeight; j++) 
            {
                let point = map.project(L.latLng(j * pixelHeight + bounds.min.y, i * pixelWidth + bounds.min.x));
                positions[ 4 * (j * tWidth + i) + 0 ] = point.x / this.scale; //(min.x + i * pixelWidth )/ this.scale;
                positions[ 4 * (j * tWidth + i)  + 1 ] = point.y / this.scale; //(min.y + j * pixelHeight) / this.scale; 
                positions[ 4 * (j * tWidth + i)  + 2 ] = 0;
                positions[ 4 * (j * tWidth + i)  + 3 ] = 1;
            }
        }

        this.posTexture = new THREE.DataTexture( positions, tWidth, tHeight, THREE.RGBAFormat, THREE.FloatType );
        this.posTexture.magFilter = THREE.LinearFilter;
        this.posTexture.minFilter = THREE.LinearFilter;
        this.posTexture.needsUpdate = true;
    }

    updateScale(s)
    {
        this.scale = s;
    }

    zoom(bounds) {
        if (bounds)
            this.bounds = getBounds(bounds);

        let rangeX = 360;
        let rangeY = 180;
        let newSize = new THREE.Vector2( this.oWidth / rangeX * (this.bounds.max.x - this.bounds.min.x) / this.tWidth, this.oHeight / rangeY * (this.bounds.max.y - this.bounds.min.y) / this.tHeight);
        let startPixel = new THREE.Vector2( this.oWidth / rangeX * Math.max(this.bounds.min.x - this.originalBounds.min.x, 0) / this.tWidth, this.oHeight / rangeY * Math.max(this.bounds.min.y - this.originalBounds.min.y, 0 ) / this.tHeight);

        this.pass.uniforms.startPos.value.copy(startPixel);
        this.pass.uniforms.size.value.copy(newSize);

        this.pass.render(this.renderer, this.zoomRT);

        this.velocityTexture = this.zoomRT.texture;
        this.generatePositions();

    }

    getData()
    {
        return { positionTexture: this.posTexture, velocityTexture: this.velocityTexture };
    }
}
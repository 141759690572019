import * as THREE from 'three';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { ShaderMaterial } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import PositionVelocityStructure from './positionVelocity.js'


class RenderTargetSequence {

    constructor(targets, pass, setUniforms)
    {
        this.targets = targets;
        this.pass = pass;
        this.setUniforms = setUniforms;
    }

    shift() {

        this.targets = [...this.targets.slice(-1), ...this.targets.slice(0, -1)];
    }

    render(renderer, gObj) {

        renderer.autoClear = false;
        this.shift();

        renderer.setRenderTarget(this.targets[0]);
        renderer.clear();

        this.targets.slice(1).reverse().forEach(target => {

            //this.setUniforms(target, gObj);
            this.pass.uniforms.points.value = target.texture;
            this.pass.uniforms.fade.value = gObj.fade;
            this.pass.render(renderer, this.targets[0])
        });

    }
}


export default class WindSimStructure {
    constructor(renderer, map, camera, shaders, windTexture, bounds, scale)
    {
        let width = 360;
        let height = 180;
        let pointsWidth = 256;
        let pointsHeight = 256;

        this.windRT = new THREE.WebGLRenderTarget( pointsWidth, pointsHeight, {
            wrapS: THREE.ClampToEdgeWrapping,
            wrapT: THREE.ClampToEdgeWrapping,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            minFilter: THREE.NearestFilter, //THREE.LinearFilter,
            magFilter: THREE.NearestFilter, //THREE.LinearFilter,
            // minFilter: THREE.LinearFilter,
            // magFilter: THREE.LinearFilter,
            stencilBuffer: false,
            depthBuffer: true
        });

        this.pointsRT = new THREE.WebGLRenderTarget( window.innerWidth, window.innerHeight, {
            wrapS: THREE.ClampToEdgeWrapping,
            wrapT: THREE.ClampToEdgeWrapping,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            stencilBuffer: false,
            depthBuffer: true
        });

        this.prevPointsRT = new THREE.WebGLRenderTarget( window.innerWidth, window.innerHeight, {
            wrapS: THREE.ClampToEdgeWrapping,
            wrapT: THREE.ClampToEdgeWrapping,
            format: THREE.RGBAFormat,
            type: THREE.FloatType,
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            stencilBuffer: false,
            depthBuffer: true
        });

        this.renderer = renderer;
        this.camera = camera;
        this.PVS = new PositionVelocityStructure(windTexture, width, height, width, height, map, bounds, renderer, shaders, scale);

        let data = this.PVS.getData();
        this.windTexture = data.velocityTexture;
        this.posTexture = data.positionTexture;

        let uv = new Float32Array(pointsWidth*pointsHeight*2);
        let pos = new Float32Array(pointsWidth*pointsHeight*3);
        for( let i = 0; i < pointsWidth; i++)
        {
            for(let j = 0; j < pointsHeight; j++) 
            {
                pos[ 3 * (j * pointsWidth + i) + 0 ] = 0;//i / width;
                pos[ 3 * (j * pointsWidth + i)  + 1 ] = 0;//j / height;
                pos[ 3 * (j * pointsWidth + i) + 2 ] = 0;//i / width;

                uv[ 2 * (j * pointsWidth + i) + 0 ] = i / width;
                uv[ 2 * (j * pointsWidth + i)  + 1 ] = j / height;
            }
        }

        const pgeometry = new THREE.BufferGeometry();
        pgeometry.setAttribute( 'position', new THREE.BufferAttribute( pos, 3 ) );
        pgeometry.setAttribute( 'uv', new THREE.BufferAttribute( uv, 2 ) );

        this.pointMaterial = new THREE.ShaderMaterial({
            uniforms: {
                posT: { type: 't', value: null },
                posSim : { type: 't', value: null },
                posSimPrev : { type: 't', value: null },
                pointSize: { type: 'f', value: 1.0 },
                lowColor: {type: 'v3', value: new THREE.Color("#FFFFFF")},
                highColor: {type: 'v3', value: new THREE.Color("#FFFFFF")},
                gradientFactor: {type: 'f', value: 1.6}
            },
            vertexShader: shaders.points_v,
            fragmentShader: shaders.points_f
        }) 

        this.pointMaterial.blending = THREE.NoBlending;
        this.pointMaterial.transparent = true;
        this.pointMaterial.depthTest = false;

        this.points = new THREE.Points( pgeometry, this.pointMaterial );
        this.points.frustumCulled = false;

        this.pointScene = new THREE.Scene();
        this.pointScene.add(this.points);

        this.windComposer = new EffectComposer(renderer, this.windRT);
        this.windSim = new ShaderPass({
            uniforms: {
                posT: { type: 't', value: null },
                init: { type: 't', value: this.posTexture },
                velocities: { type: 't', value: null },
                u_random_seed : {type: 'f', value : 1.0},
                start: {type: 'f', value : 1.0},
                u_factor: {type: 'f', value : 1.0}
            },

            vertexShader: shaders.quad_v,
            fragmentShader: shaders.windSim_f
        })
        this.windSim.needsSwap = true;

        this.windComposer.addPass(this.windSim);
        //this.windComposer.needsSwap = true;
        this.windComposer.renderToScreen = false;
        this.windSim.uniforms.posT.value = this.posTexture;

        this.prePointsPass = new ShaderPass({
            uniforms: {
                points: {type: 't', value : null},
                fade: {type: 'f', value : 0.05}
            },
            vertexShader: shaders.quad_v,
            fragmentShader: shaders.pointFade_f
        })

        this.rtSeq = new RenderTargetSequence([...Array(3).keys()].reduce((p, c) => [this.pointsRT.clone(),...p], []), this.prePointsPass) 

        this.prePointsPass.fsQuad.material.transparent = true;
        this.prePointsPass.fsQuad.material.blending = THREE.NoBlending;
        this.prePointsPass.fsQuad.material.depthTest = false;

        this.notRenderPoints = false;
    }

    getWindPos() {

        return this.rtSeq.targets[0].texture;
    }

    updateNumberParticles(width, height) {

        //this.windRT.setSize(width, height);

        this.points.geometry.dispose();

        let uv = new Float32Array(width*height*2);
        let pos = new Float32Array(width*height*3);
        for( let i = 0; i < width; i++)
        {
            for(let j = 0; j < height; j++) 
            {
                pos[ 3 * (j * width + i) + 0 ] = 0;//i / width;
                pos[ 3 * (j * width + i)  + 1 ] = 0;//j / height;
                pos[ 3 * (j * width + i) + 2 ] = 0;//i / width;

                uv[ 2 * (j * width + i) + 0 ] = i / width;
                uv[ 2 * (j * width + i)  + 1 ] = j / height;
            }
        }

        const pgeometry = new THREE.BufferGeometry();
        pgeometry.setAttribute( 'position', new THREE.BufferAttribute( pos, 3 ) );
        pgeometry.setAttribute( 'uv', new THREE.BufferAttribute( uv, 2 ) );

        this.points.geometry = pgeometry;

        this.windSim.uniforms.start.value = 1.0;
    }

    update(gObj, bounds, scale) {
        let windSim = this.windSim;
        let prePointsPass = this.prePointsPass;
        let pointMaterial = this.pointMaterial;

        if (gObj.zoomWind)
        {
            this.PVS.updateScale(scale);
            this.PVS.zoom(bounds);
    
            let data = this.PVS.getData();
            this.windTexture = data.velocityTexture;
            this.posTexture = data.positionTexture;
            gObj.zoomWind = false;
        }
        // let tmpR = this.windRTPrev;
        // this.windRTPrev = this.windRT;
        // this.windRT = tmpR;


        windSim.uniforms.init.value = this.posTexture;
        windSim.uniforms.posT.value = this.windComposer.readBuffer.texture;
        windSim.uniforms.velocities.value = this.windTexture;
        windSim.uniforms.u_factor.value = gObj.factor;
        windSim.uniforms.u_random_seed.value = Math.random();
        this.windComposer.render();

        this.renderer.autoClear = false;

        this.renderer.setRenderTarget(this.pointsRT);

        this.renderer.clear();

        if (!gObj.notRenderPoints)
        {
            // prePointsPass.uniforms.points.value = this.prevPointsRT.texture;
            // prePointsPass.uniforms.fade.value = gObj.fade;
            // prePointsPass.render(this.renderer, this.pointsRT);
            this.rtSeq.render(this.renderer, gObj);

            //renderer.clear();
            pointMaterial.uniforms.pointSize.value = gObj.pointSize;
            pointMaterial.uniforms.lowColor.value = new THREE.Color(gObj.lowVelocityColor);
            pointMaterial.uniforms.highColor.value = new THREE.Color(gObj.highVelocityColor);
            pointMaterial.uniforms.gradientFactor.value = gObj.gradientFactor;
            pointMaterial.uniforms.posSimPrev.value = this.windComposer.writeBuffer.texture;
            pointMaterial.uniforms.posSim.value = this.windComposer.readBuffer.texture;

            pointMaterial.uniforms.posT.value = this.posTexture;
            this.renderer.render(this.pointScene, this.camera);
        }
        else
        {
            this.renderer.setRenderTarget(this.rtSeq.targets[0]);
            this.renderer.clear();
            this.renderer.setRenderTarget(this.pointsRT);
        }

        this.renderer.autoClear = true;
        windSim.uniforms.start.value = 0.0;
    }

}

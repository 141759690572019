import quad_v from './shaders/quad.glslv'
import points_v from './shaders/points.glslv'
import windSim_f from './shaders/windSim.glslf'
import points_f from './shaders/points.glslf'
import pointFade_f from './shaders/pointFade.glslf'
import zoom_f from './shaders/zoom.glslf'


export default () => {

   return {
        quad_v: quad_v,
        points_v: points_v,
        windSim_f: windSim_f,
        points_f: points_f,
        pointFade_f: pointFade_f,
        zoom_f: zoom_f
   }

};
